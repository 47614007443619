var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Layout", [
    _c(
      "div",
      [
        _vm.loader ? _c("loader") : _vm._e(),
        _c(
          "b-row",
          { staticClass: "pb-4" },
          [
            _c("b-col", { attrs: { cols: "12" } }, [
              _c("h2", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("payments.manageTed.failedTedTitle")) +
                    " "
                ),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "p",
          { staticClass: "ted-data" },
          [
            _vm._v(" " + _vm._s(_vm.$t("payments.manageTed.numberTeds")) + " "),
            _c("b-badge", { attrs: { variant: "info" } }, [
              _vm._v(" " + _vm._s(_vm.consolidated_transfers.quantity) + " "),
            ]),
            _vm._v(
              " | " + _vm._s(_vm.$t("payments.manageTed.totalTedsFailed")) + " "
            ),
            _c("b-badge", { attrs: { variant: "info" } }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.setPriceFormat(
                      _vm.$t("payments.labels.currency"),
                      _vm.consolidated_transfers.amount
                    )
                  ) +
                  " "
              ),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table-pending-credit-request-list" },
          [
            _c("custom-data-table", {
              ref: "ted_table",
              attrs: {
                fields: _vm.fields,
                filters: _vm.filters,
                perPage: 10,
                sort: _vm.sort,
                query: _vm.transfer_refused_failed,
                "query-node": "getTransfersRefusedAndFailed",
              },
              on: {
                "filter-change": function ($event) {
                  return _vm.onChangeStatusFilter($event)
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "name",
                  fn: function (props) {
                    return _c(
                      "span",
                      {},
                      [
                        _c(
                          "router-link",
                          {
                            directives: [
                              { name: "b-tooltip", rawName: "v-b-tooltip" },
                            ],
                            attrs: {
                              title: _vm.$t("payments.labels.goToDriver"),
                              to: `/drivers/profile/${props.rowData.driver.id}`,
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(props.rowData.driver.name) + " "
                            ),
                            _c("i", {
                              staticClass:
                                "fa fa-external-link icon-size-driver",
                            }),
                          ]
                        ),
                      ],
                      1
                    )
                  },
                },
                {
                  key: "status",
                  fn: function (props) {
                    return _c("span", {}, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.setStatusFormat(props.rowData.status)) +
                          " "
                      ),
                    ])
                  },
                },
                {
                  key: "created_at",
                  fn: function (props) {
                    return _c("span", {}, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.formatDateUtc(props.rowData.created_at)) +
                          " "
                      ),
                    ])
                  },
                },
                {
                  key: "bankAccount",
                  fn: function (props) {
                    return _c("span", {}, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.setBankAccount(props.rowData.bank_account)
                          ) +
                          " "
                      ),
                    ])
                  },
                },
                {
                  key: "amount",
                  fn: function (props) {
                    return _c("span", {}, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.setPriceFormat(
                              _vm.$t("payments.labels.currency"),
                              props.rowData.amount
                            )
                          ) +
                          " "
                      ),
                    ])
                  },
                },
                {
                  key: "additional_data",
                  fn: function (props) {
                    return _c("span", {}, [
                      _vm._v(
                        " " +
                          _vm._s(props.rowData.additional_data.analysed_by) +
                          " "
                      ),
                    ])
                  },
                },
                {
                  key: "reason",
                  fn: function (props) {
                    return _c("span", {}, [
                      _c("div", { staticStyle: { width: "200px" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.getRefuseReason(
                                props.rowData.additional_data.reason
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ])
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }