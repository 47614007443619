<template>
  <Layout>
    <div>
      <loader v-if="loader" />
      <b-row class="pb-4">
        <b-col cols="12">
          <h2>
            {{ $t('payments.manageTed.failedTedTitle') }}
          </h2>
        </b-col>
      </b-row>

      <p class="ted-data">
        {{ $t('payments.manageTed.numberTeds') }}
        <b-badge variant="info">
          {{ consolidated_transfers.quantity }}
        </b-badge>
        | {{ $t('payments.manageTed.totalTedsFailed') }}
        <b-badge variant="info">
          {{ setPriceFormat($t('payments.labels.currency'), consolidated_transfers.amount) }}
        </b-badge>
      </p>

      <div class="table-pending-credit-request-list">
        <custom-data-table
          ref="ted_table"
          :fields="fields"
          :filters="filters"
          :perPage="10"
          :sort="sort"
          :query="transfer_refused_failed"
          :query-node="'getTransfersRefusedAndFailed'"
          @filter-change="onChangeStatusFilter($event)"
        >
          <span slot="name" slot-scope="props">
            <router-link
              v-b-tooltip
              :title="$t('payments.labels.goToDriver')"
              :to="`/drivers/profile/${props.rowData.driver.id}`"
            >
              {{ props.rowData.driver.name }}
              <i class="fa fa-external-link icon-size-driver" />
            </router-link>
          </span>
          <span slot="status" slot-scope="props">
            {{ setStatusFormat(props.rowData.status) }}
          </span>
          <span slot="created_at" slot-scope="props">
            {{ formatDateUtc(props.rowData.created_at) }}
          </span>
          <span slot="bankAccount" slot-scope="props">
            {{ setBankAccount(props.rowData.bank_account) }}
          </span>
          <span slot="amount" slot-scope="props">
            {{ setPriceFormat($t('payments.labels.currency'), props.rowData.amount) }}
          </span>
          <span slot="additional_data" slot-scope="props">
            {{ props.rowData.additional_data.analysed_by }}
          </span>
          <span slot="reason" slot-scope="props">
            <div style="width: 200px">
              {{ getRefuseReason(props.rowData.additional_data.reason) }}
            </div>
          </span>
        </custom-data-table>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@layouts/main';
import {  parseStatusTed } from '@utils/wallet';
import { formatPrice } from '@utils/numbers';
import CustomDataTable from '@components/CustomDataTable';
import TRANSFER_REFUSED_FAILED from '@graphql/ted/queries/transfer-refused-failed.gql';
import CONSOLIDATED_TRANSFERS_REFUSED_AND_FAILED from '@graphql/ted/queries/consolidated-transfers-failed.gql';
import { mapGetters } from 'vuex';
import { makeQuery } from "@graphql/middleware";
export default {
  name: 'ManageTed',
  components: {
    Layout,
    CustomDataTable,
    Loader: () => import('@components/shared/loader')
  },
  props: {
    fieldsAllow: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      count: 0,
      loader: false,
      kovi_fatura: process.env.KOVI_FATURA_URL,
      transfer_refused_failed: TRANSFER_REFUSED_FAILED,
      consolidated_transfers: {amount: 0, quantity: 0},
      status: 'ACTIVE',
      refuse_errors: [
        {
          code: 'invalidBranchCode',
          error: 'INVALID_BRANCH_CODE',
        },
        {
          code: 'invalidAccountNumber',
          error: 'INVALID_ACCOUNT_NUMBER'
        },
        {
          code: 'invalidBankCode',
          error: 'INVALID_BANK_CODE'
        },
        {
          code: 'invalidName',
          error: 'INVALID_NAME'
        },
        {
          code: 'invalidTaxId',
          error: 'INVALID_TAX_ID'
        },
        {
          code: 'invalidAmount',
          error: 'INVALID_AMOUNT'
        }
      ],
      fields: [
        {
          name: 'name',
          title: this.$t('payments.manageTed.table.driverName')
        },
        {
          name: 'status',
          title: this.$t('payments.manageTed.table.status')
        },
        {
          name: 'bankAccount',
          title: this.$t('payments.manageTed.table.account')
        },
        {
          name: 'amount',
          title: this.$t('payments.manageTed.table.amount'),
        },
        {
          name: 'additional_data',
          title: this.$t('payments.manageTed.table.recusedBy'),
        },
        {
          name: 'reason',
          title: this.$t('payments.manageTed.table.reason'),
        },
        {
          name: 'created_at',
          title: this.$t('payments.manageTed.table.created_at'),
          sortField: 'created_at'
        }
      ],
      sort: [['created_at', 'DESC']],
    }
  },
  computed: {
    ...mapGetters('user', ['attributes', 'findGroups']),
    filters() {
      return {
        query: {
          type: 'textfield',
          props: {
            placeholder: this.$t('payments.inputs.placeholder.searchNameCpf'),
          },
          valueParserType: String,
          graphqlQuery(value) {
            return {
               $or: [
                {
                  name: {
                    $like: `%${value}%`,
                  },
                },
                {
                  cpf: {
                    $like: `%${value}%`,
                  }
                }
               ]
            };
          },
        },
        status: {
          type: 'select',
          cols: 2,
          props: {
            label: 'Status',
            options: [
              {
                value: null,
                text: `${this.$t('payments.enumParseStatusTed.REFUSED')}/${this.$t('payments.enumParseStatusTed.FAILED')}`,
              },
              {
                value: 'failed',
                text: this.$t('payments.enumParseStatusTed.FAILED'),
              },
              {
                value: 'refused',
                text: this.$t('payments.enumParseStatusTed.REFUSED'),
              }
            ],
          },
          graphqlQuery(value) {
            return {
              status: value,
            };
          },
        }
      }
    },
  },
  mounted() {
    this.getConsolidatedTransfers()
  },
  methods: {
    onChangeStatusFilter(event) {
      if (event.filterName === 'status') {
        const status = event.event?.status
        this.getConsolidatedTransfers(status)
      }
    },
    setStatusFormat: parseStatusTed,
    getConsolidatedTransfers(status) {
      makeQuery(
        CONSOLIDATED_TRANSFERS_REFUSED_AND_FAILED,
        {
          filters: { status }
        }
      )
      .then((result) => {
        this.consolidated_transfers = result.data.getConsolidatedTransfersRefusedAndFailed;
      });
    },
    getRefuseReason(reason) {
      const error = this.refuse_errors.find(error => error.code === reason) || null
      return (error) ? this.$t(`payments.manageTed.errors.${error.error}`)  : reason
    },
    formatDateUtc (date) {
      return this.$moment.utc(date).format('DD/MM/YYYY')
    },
    setBankAccount (bankAccount) {
      return `${bankAccount.bank_name} - Ag: ${bankAccount.branch} / Conta: ${bankAccount.number}`
    },
    getTransactionOperatonErrorMsg (status_code) {
      return {
        404: this.$t('payments.creditRequest.error404Text'),
        422: this.$t('payments.creditRequest.error422Text'),
        500: this.$t('payments.creditRequest.error500Text')
      }[status_code];
    },

    showErrorModal(status_code) {
      this.$swal({
        type: 'error',
        title: this.$t('payments.creditRequest.modalErrorTitle'),
        text: (status_code === null) ? this.$t('payments.creditRequest.modalErrorText') : this.getTransactionOperatonErrorMsg(status_code),
        showConfirmButton: false,
        timer: 2500,
      }).then(() => console.log(true));
    },

    showSuccessModal() {
      this.$swal({
        type: 'success',
        title: this.$t('payments.creditRequest.modalSuccessTitle'),
        text: this.$t('payments.creditRequest.modalSuccessText'),
        showConfirmButton: false,
        timer: 2500,
      }).then(() => console.log(true));
    },
    setPriceFormat(_currency, _value) {
      return formatPrice(_currency, _value);
    },
  },
};
</script>

<style scoped>
  .ted-data {
    margin-top: -24px;
    margin-bottom: 40px;
  }

  .select-all-btn {
    margin-bottom: 10px;
    margin-right: 5px;
  }
  .custom-data-table__pagination {
    margin-bottom: 40px;
  }
  .icon-size-driver {
    width: 12px;
    text-align: center;
  }
</style>
